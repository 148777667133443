import { createSelector } from 'reselect';
import { jobsStateType } from './jobs.reducer';
import { denormalize } from 'normalizr';
import { jobSchema } from '../store.schema';
import { JobType } from './jobs.types';
import { resolveMeterType } from '../../helper/MeterHelper';
import { hasMultipleValues } from '../../helper/GroupHelper';
import { searchSelectors } from '../search';
import { applicationSelectors } from '../application';

const getSubState = (state: any) => state.jobs as jobsStateType;

const isLoadingBlocked = createSelector(getSubState, (state) => state.blocked);

const getJobIds = createSelector(getSubState, (state) => state.jobs);
const getJobEntities = createSelector(getSubState, (state) => state.entities);
const jobsDenormalized = createSelector(getJobIds, getJobEntities, (jobs, entities) => denormalize(jobs, [jobSchema], entities));

const getJobs = createSelector(jobsDenormalized, (jobs) =>
    (jobs as JobType[])?.map((job) => {
        return {
            ...job,
            jobInfo: {
                meterType: resolveMeterType(job?.meter),
            },
        } as JobType;
    }),
);

const getPreselectedJobId = createSelector(getJobIds, applicationSelectors.isPreselectJobIfSingleEnabled, (jobIds, isPreselectJobIfSingleEnabled) => {
    if (isPreselectJobIfSingleEnabled && jobIds?.length === 1) {
        return jobIds[0];
    } else {
        return undefined;
    }
});

const getJobsCount = createSelector(getJobIds, (jobIds) => jobIds?.length || 0);

const getJobsDone = createSelector(getJobs, (jobs) => jobs.filter((j) => j.done));
const getCountJobsDone = createSelector(getJobsDone, (jobs) => jobs.length);

const getJobsOpen = createSelector(getJobs, (jobs) => jobs.filter((j) => !j.done));
const getCountJobsOpen = createSelector(getJobsOpen, (jobs) => jobs?.length || 0);

const getJobById = (jobId: string) =>
    createSelector(getSubState, (state) => {
        return denormalize(jobId, jobSchema, state.entities) as JobType;
    });

const isLoading = createSelector(getSubState, (state) => state.loading && state.jobs.length === 0);

const hasJobs = createSelector(getJobIds, (ids) => ids.length > 0);

const getSortedJobs = createSelector(getJobs, (jobs) =>
    (jobs || []).sort((a, b) => (a.meter.meterNumber && b.meter.meterNumber ? a.meter.meterNumber.localeCompare(b.meter.meterNumber) : 0)),
);

const getFilteredJobs = createSelector(getSortedJobs, searchSelectors.getSearchArray, (jobs, searchArray) =>
    jobs?.filter((job) => {
        if (job.utils?.searchString && searchArray && searchArray.length > 0) {
            return searchArray.every((s) => !!job.utils?.searchString?.toUpperCase()?.includes(s?.toUpperCase()));
        }

        return false;
    }),
);

const haveJobsMultipleValues = (key: string) => createSelector(getJobs, (jobs) => hasMultipleValues(jobs, key));

const getJobsByIds = (ids: string[]) =>
    createSelector(getJobs, (jobs) => {
        return jobs.filter((job) => ids.includes(job.id));
    });

const getJobsByAccountId = (accountId: string) => createSelector(getJobs, (jobs) => jobs.filter((j) => j.login === accountId));

const getJobIdsByAccountId = (accountId: string) => createSelector(getJobs, (jobs) => jobs.filter((j) => j.login === accountId).map((job) => job.id));

const getShareableJobsByAccountId = (accountId: string) =>
    createSelector(getJobsByAccountId(accountId), (jobs) => jobs.filter((job) => job.jobPermissions?.reShare));

export const isAnyJobReshareAllowed = createSelector(getJobs, applicationSelectors.isGlobalReshareAllowed, (jobs, globalReshareAllowed) => {
    const hasAnyJobConfigAnOverrideForReshareAllowed = jobs?.some((j) => !!j.config?.features?.reshareAllowed);

    if (hasAnyJobConfigAnOverrideForReshareAllowed) {
        return true;
    }

    return globalReshareAllowed;
});

const hasShareableJobs = createSelector(getJobs, (jobs) => jobs.filter((j) => j.jobPermissions?.reShare).length > 0);
const hasShareableJobsByAccountId = (accountId: string) =>
    createSelector(getShareableJobsByAccountId(accountId), (jobs) => jobs.filter((j) => j.jobPermissions?.reShare).length > 0);

const isSending = createSelector(getSubState, (state) => state.sending);

export default {
    isLoading,
    isLoadingBlocked,
    hasJobs,
    getJobs,
    getJobsCount,
    getJobById,
    getPreselectedJobId,

    getCountJobsDone,
    getCountJobsOpen,

    getJobIdsByAccountId,

    hasShareableJobs,
    hasShareableJobsByAccountId,
    isAnyJobReshareAllowed,

    getFilteredJobs,
    haveJobsMultipleValues,

    getJobsDone,

    getJobsByIds,
    getJobsByAccountId,
    getShareableJobsByAccountId,
    isSending,
} as const;
