import React, { ComponentPropsWithRef, useCallback, useEffect, useState } from 'react';

import './Carousel.style.css';
import { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import classNames from 'classnames';

export interface PreviewImage {
    index?: number;
    base64: string;
}

interface CarouselProps {
    images: Array<PreviewImage>;
    options?: EmblaOptionsType;
    controlsEnabled?: boolean;
}

const Carousel: React.FC<CarouselProps> = ({ images, options, controlsEnabled = true }: CarouselProps) => {
    const [ref, api] = useEmblaCarousel(options);
    const { onPrevButtonClick, onNextButtonClick, prevBtnDisabled, nextBtnDisabled } = usePrevNextButtons(api);

    useEffect(() => {
        if (!controlsEnabled) {
            return;
        }

        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'ArrowLeft' && !prevBtnDisabled) {
                onPrevButtonClick();
            } else if (e.key === 'ArrowRight' && !nextBtnDisabled) {
                onNextButtonClick();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [onPrevButtonClick, onNextButtonClick, prevBtnDisabled, nextBtnDisabled, controlsEnabled]);

    return (
        <>
            <div className="relative h-full">
                <section className="embla">
                    <div className="embla__viewport" ref={ref}>
                        <div className="embla__container">
                            {images.map((i, index) => (
                                <div className="embla__slide" key={index}>
                                    <img src={i.base64} alt="meter-reading" />
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                {controlsEnabled && (
                    <div
                        className="absolute left-0 right-0 z-10 flex items-center justify-between pointer-events-none"
                        style={{
                            top: '50%',
                            transform: 'translateY(-50%)',
                        }}
                    >
                        <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} className="pointer-events-auto" />
                        <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} className="pointer-events-auto" />
                    </div>
                )}
            </div>
        </>
    );
};

type UsePrevNextButtonsType = {
    prevBtnDisabled: boolean;
    nextBtnDisabled: boolean;
    onPrevButtonClick: () => void;
    onNextButtonClick: () => void;
};

export const usePrevNextButtons = (emblaApi: EmblaCarouselType | undefined): UsePrevNextButtonsType => {
    const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
    const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

    const onPrevButtonClick = useCallback(() => {
        if (!emblaApi) return;
        emblaApi.scrollPrev();
    }, [emblaApi]);

    const onNextButtonClick = useCallback(() => {
        if (!emblaApi) return;
        emblaApi.scrollNext();
    }, [emblaApi]);

    const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
        setPrevBtnDisabled(!emblaApi.canScrollPrev());
        setNextBtnDisabled(!emblaApi.canScrollNext());
    }, []);

    useEffect(() => {
        if (!emblaApi) return;

        onSelect(emblaApi);
        emblaApi.on('reInit', onSelect).on('select', onSelect);
    }, [emblaApi, onSelect]);

    return {
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick,
    };
};

type PropType = ComponentPropsWithRef<'button'>;

export const PrevButton: React.FC<PropType> = (props) => {
    const { children, disabled, ...restProps } = props;

    return (
        <button
            className={classNames('embla__button embla__button--prev hover:opacity-60 transition-opacity pointer-events-auto')}
            type="button"
            {...restProps}
        >
            <svg
                className={classNames('embla__button__svg', {
                    'fill-current text-gray-300 cursor-not-allowed': disabled,
                })}
                viewBox="0 0 532 532"
            >
                <path
                    fill="currentColor"
                    d="M355.66 11.354c13.793-13.805 36.208-13.805 50.001 0 13.785 13.804 13.785 36.238 0 50.034L201.22 266l204.442 204.61c13.785 13.805 13.785 36.239 0 50.044-13.793 13.796-36.208 13.796-50.002 0a5994246.277 5994246.277 0 0 0-229.332-229.454 35.065 35.065 0 0 1-10.326-25.126c0-9.2 3.393-18.26 10.326-25.2C172.192 194.973 332.731 34.31 355.66 11.354Z"
                />
            </svg>
            {children}
        </button>
    );
};

export const NextButton: React.FC<PropType> = (props) => {
    const { children, disabled, ...restProps } = props;

    return (
        <button className="embla__button embla__button--next hover:opacity-60 transition-opacity pointer-events-auto" type="button" {...restProps}>
            <svg
                className={classNames('embla__button__svg', {
                    'fill-current text-gray-300 cursor-not-allowed': disabled,
                })}
                viewBox="0 0 532 532"
            >
                <path
                    fill="currentColor"
                    d="M176.34 520.646c-13.793 13.805-36.208 13.805-50.001 0-13.785-13.804-13.785-36.238 0-50.034L330.78 266 126.34 61.391c-13.785-13.805-13.785-36.239 0-50.044 13.793-13.796 36.208-13.796 50.002 0 22.928 22.947 206.395 206.507 229.332 229.454a35.065 35.065 0 0 1 10.326 25.126c0 9.2-3.393 18.26-10.326 25.2-45.865 45.901-206.404 206.564-229.332 229.52Z"
                />
            </svg>
            {children}
        </button>
    );
};

export default Carousel;
