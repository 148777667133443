import axios from 'axios';

const fetchReadingImagesForMeterRateTask = async (jobId: string, taskId: string, imageId: string, token: string, abortController: AbortController) => {
    const response = await axios.get<Blob>(`/api/pro/job/${jobId}/meter-rate-tasks/${taskId}/result-images/${imageId}`, {
        responseType: 'blob',
        signal: abortController.signal,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64data = reader.result;
            if (typeof base64data === 'string') {
                resolve(base64data);
            } else {
                reject(new Error('Failed to convert readingImage to base64'));
            }
        };

        reader.onerror = reject;
        reader.readAsDataURL(response.data);
    });
};

export default {
    fetchReadingImagesForMeterRateTask,
} as const;
