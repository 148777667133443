import React, { useEffect, useMemo, useState } from 'react';
import './JobFilterToolbar.less';
import Input from '../../../../components/envago/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { searchActions, searchSelectors } from '../../../../redux/search';
import { useDebounce } from '../../../../helper/Hooks';

const JobFilterToolbar = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const searchValue = useSelector(searchSelectors.getSearchValue);

    const [search, setSearch] = useState<string>('');
    const debouncedValue = useDebounce(search, 250);

    const loading = useMemo(() => {
        return search !== searchValue;
    }, [search, searchValue]);

    useEffect(() => {
        dispatch(searchActions.setSearchValue(debouncedValue));
    }, [debouncedValue]);

    return (
        <div className={'h-12 mb-3 flex items-center justify-center'}>
            <div className="flex flex-1 items-baseline relative">
                <Input
                    placeholder={t('common.search')}
                    value={search}
                    onChange={(value) => {
                        setSearch(value);
                    }}
                    className={'flex-1 pr-10 h-10 focus:ring focus:border-outline-none'}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') e.currentTarget.blur();
                    }}
                />
                {loading && (
                    <svg
                        className="absolute right-3 top-1/3 w-5 h-5 animate-spin text-gray-500"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <circle cx="12" cy="12" r="10" strokeOpacity="0.3"></circle>
                        <path d="M12 2a10 10 0 0110 10" strokeOpacity="0.8"></path>
                    </svg>
                )}
            </div>
        </div>
    );
};

export default JobFilterToolbar;
