import { createSelector } from 'reselect';
import { MeterRateTaskType, ReadingEventType } from '../jobs/jobs.types';
import { applicationSelectors } from '../application';
import dayjs from 'dayjs';
import { resolveLastReadingResultPerDay } from '../../helper/EvaluationHelper';

const getUnifiedReadingsForTask = (task: MeterRateTaskType) =>
    createSelector(
        [applicationSelectors.isEvaluationShowCurrentReadingsAllowed, applicationSelectors.isEvaluationUseHistoricReadingsAllowed],
        (showCurrentResultsAllowed, showHistoricReadingsAllowed) => {
            const readings: ReadingEventType[] = [];

            if (showHistoricReadingsAllowed) {
                task.readingEvents?.forEach((reading) => {
                    if (reading.readingValue != null) {
                        readings.push(reading);
                    }
                });
            }

            if (showCurrentResultsAllowed) {
                const lastReadingResultsPerDay = resolveLastReadingResultPerDay(task);

                if (lastReadingResultsPerDay) {
                    Object.values(lastReadingResultsPerDay).forEach((reading) => readings.push(reading));
                }
            }

            return readings.sort((a, b) => {
                if (!a.readingDate || !b.readingDate) return 0;

                if (dayjs(a.readingDate).isBefore(b.readingDate)) {
                    return 1;
                } else {
                    return -1;
                }
            });
        },
    );

const evaluationSelectors = {
    getUnifiedReadingsForTask,
};

export default evaluationSelectors;
