import { JobType, MeterRateTaskType, ReadingEventType } from '../redux/jobs/jobs.types';
import dayjs from 'dayjs';

export const isJobEvaluable = (job: JobType) =>
    job.meter.meterRateTasks.some((task) => {
        if (!task.readingEvents) return false;

        return (job.done && task.readingEvents.length > 0) || (!job.done && task.readingEvents.length > 1);
    });

export const resolveLastReadingResultPerDay = (task: MeterRateTaskType | undefined) => {
    if (!task || !task.readingResults || task.readingResults.length === 0) {
        return undefined;
    }

    return task.readingResults.reduce<{
        [key: string]: ReadingEventType;
    }>((acc, { readingValue, readingDate, created }) => {
        const formattedReadingDate = dayjs(readingDate).format('DD.MM.YYYY');

        if (formattedReadingDate in acc) {
            const currDate = dayjs(created || readingDate);
            const accDate = dayjs(acc[formattedReadingDate].created || acc[formattedReadingDate].readingDate);

            if (currDate.isAfter(accDate)) {
                return Object.assign(acc, {
                    [formattedReadingDate]: {
                        created,
                        readingDate,
                        readingValue,
                        reason: 'OTHER',
                        otherReason: 'OTHER_KSA',
                    },
                });
            } else {
                return acc;
            }
        } else {
            return Object.assign(acc, {
                [formattedReadingDate]: {
                    created,
                    readingDate,
                    readingValue,
                    reason: 'OTHER',
                    otherReason: 'OTHER_KSA',
                },
            });
        }
    }, {});
};
