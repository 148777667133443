export default {
    'update': {
        'dialog': {
            'title': 'Activate new version',
            'content': 'A new version of this application has been loaded and can now be activated',
            'hint': 'By activating the page will be reloaded. Unsaved entries may be lost.',
            'buttonNegative': 'Later',
            'buttonPositive': 'Activate now'
        }
    },
    'login': {
        'title': 'Welcome to {{applicationName}}',
        'subtitle': 'Please log in with the login details your received from us:',
        'qrcode': {
            'error': {
                'access': {
                    'dialogtitle': 'Camera access not possible',
                    'title': 'We couldn\'t open your camera.',
                    'text': 'Please use your login details to proceed',
                },
                'device': {
                    'title': 'You can\'t use the qr-code login with your device - or you didn\'t granted the rights to access your camera.',
                },
            },
            'teaser': {
                'text': 'Have you received a QR code on a reading card or in a letter? And do you have a webcam?<1/> Scan the QR-Code with your webcam to login:',
                'access': 'To log in with the QR code, we need access to your camera.',
            },
            'dialog': {
                'instruction': 'Hold the QR code from your reading card or letter in front of the camera:',
            },
            'buttons': {
                'login': 'Sign in with a QR code',
                'close': 'Close',
            },
        },
        'labels': {
            'username': 'Username',
            'password': 'Password',
            'qr-login': 'QR-Code Login',
        },
        'placeholders': {
            'username': 'My Username',
            'password': 'Password',
        },
        'buttons': {
            'login': 'Login',
        },
        'tabs': {
            'login': 'Login with Login Details',
            'unknownUserReading': 'Reading without Login Details'
        },
        'error': {
            'login': {
                'text': 'Login failed. Please check your entered data.',
            },
            'dialog': {
                'title': 'Login failed',
                'description': 'We are sorry, the login with these credentials is currently not possible',
                'content': 'If you received a reading card, perhaps it\'s not yet synced.<1 /><2 />Please try again later!',
                'buttons': {
                    'close': 'Close',
                    'unknownUserReading': 'Meterreading without login'
                },
                'unknownUserReading': 'Alternatively, you can submit your meter reading here without logging in:',
            },
        },
        'usage': {
            'text': 'This page is being provided by {{applicationName}}. By login and using, you declare that you agree to our <3>data privacy terms</3>.',
        },
    },
    'unknownUserReading': {
        'loginScreen': {
            'text': 'You want to send your meter reading but did not receive a letter?',
            'buttonText': 'Meter Reading without Login'
        },
        'doneScreen': {
            'title': 'Your meter reading is complete',
            'message': 'Thank you! Your meter reading has been successfully transmitted.'
        },
        'screenTitle': 'Your Meter Reading',
        'errorHandling': {
            'isAuthPending':'You are being logged in, please wait.',
            'isAuthError': 'Error logging in. Please try again later.',
            'tokenExpired': 'Your session has expired, please log in again.',
            'featureNotAvailable': 'Reading without Login Details is currently not possible. Please try again later.',
        },
        'introduction': {
            // 'title': 'Your meter reading',
            'screenHint': 'You can also tell us your meter reading without login',
            'pinReceivedHint': 'If you have received access data by letter or a meter reading card from our meter reader, please register here:'
        },
        'meterStep': {
            'title': 'Meter selection',
            'description': 'Please first enter your meter number and select the type of meter you have.',
            'validationError': 'Please enter the meter number in full.',
        },
        'meterReadingStep': {
            'title': 'Meter reading',
            'description': 'Please now enter the meter readings of your meter {{preposition}} decimal places.',
            'validationError': 'Please enter the meter readings in full.',
        },
        'contactStep': {
            'title': 'Contact details',
            'description': 'So that we can assign your details correctly, we need your contact details and the address of the meter.',
            'validationError': 'Please fill in all required fields (*).',
        },
        'summaryStep': {
            'title': 'Summary',
            'description': 'Please check your entries before submitting the data'
        },
        'information': {
            'meterNumberHint': 'We need the complete meter number to correctly assign your meter reading.',
            'emailProcessing': 'Note: The use of the e-mail function is voluntary. The e-mail address will not be stored permanently without your consent.',
            'privacyPolicy': 'This page is being provided by {{applicationName}}. I have taken note of the <3>data protection information</3> in accordance with Art. 13 and 14 GDPR.',
        },
        'notification': {
            'readingRequest': {
                'error': {
                    'title': 'Transmission failed',
                    'description': 'The meter reading could not be transmitted. Please try again later.',
                },
                'success': {
                    'title': 'Meter reading transmitted',
                    'description': 'The meter reading was successfully transmitted.',
                }
            },
            'readingReceipt': {
                'error': {
                    'title': 'Copy via e-mail failed',
                    'description': 'A copy of your meter readings could not be sent to the e-mail address you provided.',
                },
                'success': {
                    'title': 'Copy sent via e-mail',
                    'description': 'A copy of your meter readings has been sent to the e-mail address you provided.',
                }
            }
        },
        'subTitle': {
            'meterNumber': 'Meternumber',
            'meterType': 'Metertype',
            'meterSubType': 'Subtype',
            'meterReading': 'Reading results',
            'readingDate': 'Reading date',
            'contact': 'Your contact details',
            'meterAddress': 'Meter address',
            'readingReason': 'Reading reason',
            'meter': 'Your meter',
            'readingValues': 'Reading values',
            'email': 'Would you like a copy of your meter readings by e-mail?',
            'privacyPolicy': 'Privacy policy',
        },
        'description': {
            'readingDate': 'When did you record the meter reading?',
            'dataPrivacyRequired': 'To submit your data, please agree to the privacy policy.',
        },
        'label': {
            'meterNumber': 'Meternumber',
            'meterType': 'Metertype',
            'meterSubType': 'Subtype',
            'queryTransformerOrMWhPostDecimals': 'Record decimal places for transformer or MWh metering',
            'contact': 'Contact information',
            'firstname': 'Firstname',
            'lastname': 'Lastname',
            'meterAddress': 'Meter address',
            'street': 'Street',
            'houseNumber': 'House Number',
            'zip': 'ZIP',
            'city': 'City',
            'readingReason': 'Reading reason',
            'readingDate': 'Reading date',
            'email': 'E-Mail'

        },
        'placeholder': {
            'meterNumber': 'Meternumber',
            'firstname': 'Firstname',
            'lastname': 'Lastname',
            'street': 'Street',
            'houseNumber': 'House Number',
            'zip': 'ZIP',
            'city': 'City',
            'email': 'E-Mail',
        },
        'meterType': {
            'ELECTRIC_METER': 'Electric Meter',
            'HEAT_METER': 'Heat Meter',
            'GAS_METER': 'Gas Meter',
            'WATER_METER': 'Water Meter',
            'WATER_WARM_METER': 'Warm Water Meter',
        },
        'meterSubType': {
            'ELECTRIC_METER_TARIFFS_ONE': 'Electric Meter (1 Tariff)',
            'ELECTRIC_METER_TARIFFS_TWO': 'Electric Meter (2 Tariffs)',
            'ELECTRIC_METER_FEED_IN_METER': 'Electric Meter (Feed-In)',
            'OTHER': 'other'
        },
        'readingReason': {
            'ANNUAL_READING': 'Annual reading',
            'CONTROL_READING': 'Control reading',
            'PROVIDER_CHANGE': 'Change of provider / relocation',
        },
        'rateName': {
            'Verbrauch': 'Consumption',
            'Einspeisung': 'Feed-In',
        },
        'button': {
            'refreshToken': 'Log in',
            'toHome': 'Back to home',
            'removeMeterRate': 'Remove tariff',
            'loginWithPIN': 'Log in with access data',
            'continue': 'Continue',
            'submit': 'Submit'
        },
        'preposition': {
            'with': 'with',
            'without': 'without'
        }
    },
    'home': {
        'title': 'My Meter Reading Portal - {{applicationName}}',
        'introduction': {
            'title': 'Welcome',
            'reading': 'Find your meters in the menu <1>$t(menu.reading)</1>. There you can enter and send your meter readings quickly and easily.',
            'management': 'To share readings, click on <1>$t(menu.management)</1>. As a property manager, you share e.g. your readings with your property manager or the caretakers.',
        },
        'account': {
            'title': 'My Account',
            'title_plural': 'My Accounts',
            'multipleAccounts': 'You have registered with several accounts:',
            'login': {
                'info': 'You are logged in as <1>{{name}}</1>.',
                'parent': 'This Account was created by <1>{{parent}}</1>.',
                'shared': 'shared by {{parent}}',
            },
        },
        'readings': {
            'title': 'My Readings',
            'open': 'There are <1>{{jobCountOpen}} </1> pending meter reading jobs.',
            'done': 'All your meter readings jobs are done!',
            'labels': {
                'totalReadings': 'total readings',
                'progress': 'progress (done)',
            },
            'buttons': {
                'openReadings': 'open $t(common.reading_plural)',
            },
        },
    },
    'jobs': {
        'search': {
            'noResults': 'there are no results for the term <1/> <3>{{searchValue}}</3> <5/>',
        },
        'labels': {
            'readingsDone': 'done',
        },
        'share': {
            'title': 'Share Reading Jobs',
            'placeholders': {
                'name': 'Property Management Doe',
                'email': 'john@doe.com',
            },
            'descriptions': {
                'accessName': 'Define a name for the new access. The access name will later be displayed on the overview page of your shared accesses together with the respective progress of the reading. The name is also visible for the created access.',
                'reshareAllowed': 'By checking the box, you allow the user of this access to split his meters into further sub-accesses. If the access you created reads the meters directly, you do not need to activate this function.',
            },
            'defaultShareName': 'Reading Job {{meterNumber}}',
            'defaultShareName_plural': 'Reading Job {{meterNumber}} et al.',
            'labels': {
                'accessName': 'Access Name',
                'additionalOptions': 'Additional Options',
                'reshareAllowed': 'reshare allowed',
                'accessEmail': 'E-Mails of Access',
                'username': 'Username',
                'password': 'Password',
                'tokenLogin': 'Direct-Link',
                'webLogin': 'Web-Portal-URL',
                'qrcode': 'QR Code',
            },
            'sending': 'Sharing Reading Jobs...',
            'success': {
                'summary': 'The access "{{name}}" was created successfully and contains {{count}} meter.',
                'summary_plural': 'The access "{{name}}" was created successfully and contains {{count}} meters.',
                'message': 'You can now share the following login data for this access.',
                'loginMethod': {
                    'email': {
                        'title': 'Send access data via E-Mail',
                        'description':
                            'You can send the login data directly to the e-mail addresses of the access.',
                        'notifications': {
                            'valid': 'The emails you selected are valid.',
                            'warning': 'The emails you selected are not valid!',
                        },
                        'send' : 'The login has been sent to {{email}}.'
                    },
                    'triggerText': 'show optional login options',
                    'direct': {
                        'title': 'Direct login via link',
                        'description': 'The provided link already contains the login data.'
                    },
                    'web': {
                        'title': 'Manual login',
                        'description': 'Sharing the manual login allows the login via username and password.',
                    },
                },
            },
            'buttons': {
                'createAccess': 'Create Access',
                'copyTokenUrl': 'Copy Token-URL',
                'send': 'Send',
                'close': 'Close',
            },
        },
        'details': {
            'title': 'Meter Reading Job',
            'doneHint': '<0>This meter has already been read.</0><1/> You can correct the meter reading and send us a new reading.',
            'doneHintLate': '<0>This meter has already been read.</0><1/> You can correct the meter reading and send us a new reading.',
            'doneHintOverdue': '<0>This meter has already been read.</0><1/> You can correct the meter reading and send us a new reading.',
            'sending': 'Sending data...',
            'multiObisWarning': 'This meter has <1 className=\'font-medium\'>{{meterRateCount}} tariffs</1>: Please note the rate names!',
            'send': {
                'confirm': 'Send anyway',
                'cancel': 'Correct values',
            },
            'labels': {
                'meterNumber': '$t(meterReadingJob.meterNumber)',
                'meterType': 'Metertype',
                'customer': '$t(meterReadingJob.customer.title)',
                'customerNumber': '$t(meterReadingJob.customer.customernumber)',
                'deliveryPoint': 'Address',
                'readingValue': 'Reading Value',
                'reading': 'Meter Reading',
                'readingImage': 'Reading Photo',
                'obis': 'Rate',
                'partner': 'Contract Partner',
                'objectKey': 'Object',
                'locationDescription': 'Location',
                'hint': 'Hint',
                'readingDate': 'Reading date',
                'readingHint': 'Reading hint',
                'targetReadingDate': '$t(common.targetReadingDate)',
                'meterRateTaskReadingImages': 'Reading photos',
            },
            'readingHint': {
                'placeholder': 'No hint',
                'meterChanged': 'Meter was changed',
                'freetext': {
                    'label': 'other hint',
                    'placeholder': 'Hint for the reading...',
                },
            },
            'warnings': {
                'constraints': {
                    'beforeEarliestExecution': {
                        'title': 'The reading period has not yet started',
                        'description': 'The meter reading of this reading was requested from <1>{{earliestExecution}}</1>.<br/> You can enter the reading, but the actual consumption may be estimated.',
                    },
                    'afterLatestExecution': {
                        'title': 'The reading period has expired',
                        'description': 'The meter reading of this reading was requested until <1>{{latestExecution}}</1>.<br/> You can carry enter the reading, but the meter reading may no longer be taken into account for billing.',
                    },
                    'beforeSendingReadingValuesIsAllowedBasedOnEarliestExecutionAndDuration': {
                        'title': 'The reading period has not yet started',
                        'description': 'The meter reading for this reading can only be sent starting from <1>{{earliestExecution}}</1>.<br/> If you have any questions, please contact customer service.'
                    },
                    'afterSendingReadingValuesIsAllowedBasedOnLatestExecutionAndDuration': {
                        'title': 'The reading period has expired',
                        'description': 'The meter reading for this reading can no longer be submitted as the reading period has expired.<br/> If you have any questions, please contact customer service.'
                    }
                },
                'check': {
                    'title': 'Please check the values',
                    'message': 'Please recheck the values.',
                    'details': 'Please make sure that you have read the correct number of decimal places and that you have not accidentally `slipped` the decimal place.',
                },
                'incomplete': {
                    'title': 'Incomplete values',
                    'message': 'Your meter has several rates, but you have only entered values for some of them.',
                    'details': 'Billing with your provider can usually only be carried out correctly if all meter readings are available. If meter readings are missing, they may be estimated.',
                },
                'invalidText': 'Please enter exactly {{count}} decimal place.',
                'invalidText_plural': 'Please enter exactly {{count}} decimal places.',
                'validationWarningText': 'The meter reading is not plausible, please check your entry.',
                'historicValidationWarningText': 'The meter reading is not plausible, the value is lower than the last reading.',
                 // fixme: is not processed | 'historicValidationErrorText': 'The meter reading is not plausible, the value is lower than the last reading. Please add a reading photo or a reading hint.',
                'historicValidationFeedInMeterWarningText': 'The meter reading is not plausible, the value is lower than the last reading.',
                'continuousReadingsValidationWarningText': 'The meter reading is not plausible, the value is lower than the last reading.',
                'readingDateWarning': 'Invalid reading date selected: Please select a reading date between {{earliest}} and {{latest}}. The reading date was reset to {{readingDate}}.',
                'imageRequiredError': 'The meter reading is not plausible. Please add a reading photo.',
                'imageOrCommentRequiredError': 'The meter reading is not plausible. Please add a reading photo or a reading hint.',
                // fixme: is not processed | 'imageAndCommentRequiredError': 'The meter reading is not plausible. Please add a reading photo and a reading hint.',
                'imageRequiredFeedInMeterError': 'The meter reading is not plausible. Please add a reading photo.',
                'imageOrCommentRequiredFeedInMeterError': 'The meter reading is not plausible. Please add a reading photo or a reading hint.',
                // fixme: is not processed | 'imageAndCommentRequiredFeedInMeterError': 'The meter reading is not plausible. Please add a reading photo and a reading hint.',
                'hintRequiredError': 'A meter reading is not plausible. Please enter a reading hint.',
                'hintMinLengthWarning': 'The reading hint should contain at least 5 characters.',
                'imageAlwaysRequiredHint': 'Please add a photo of your meter reading to send your results.',
                'imageAlwaysRequiredHint_plural': 'Please add photos of your meter readings to send your results.'
            },
            'tooltips': {
                'readingValueGreaterThanMaximumValue' : 'The reading value is greater than expected.',
                'readingValueLessThanMinimumValue' : 'The reading value is smaller than expected.',
            },
            'imageUploaded': 'add reading image',
            'dialogs': {
                'meterRateTaskReadingImages': {
                    'title': 'Reading photos',
                    'closeButton': 'Close',
                }
            },
            'buttons': {
                'uploadImage': 'add reading image',
                'send': 'send',
                'share': 'share',
                'resendMeterReading' : 'Resubmit Meter Reading',
                'resendMeterReading_plural' : 'Resubmit Meter Readings',
            },
        },
        'home': {
            'title': 'Overview: Meter Readings',
            'expireWarning': '<0>Attention</0> on {{expireDate}} {{expireCount}} jobs will expire, that are not yet done!',
            'statistic': {
                'title': '$t(common.reading_plural)',
                'total': 'total',
                'done': 'done',
            },
            'period': {
                'title': 'Reading Periods',
                'description': 'For your bill to be created correctly, the meter readings must be sent to us in good time. Here you can see the periods until when we need your meter reading:',
                'showMeters': 'Show meters',
            },
        },
        'unknownMeterReading': {
            'listItemLabel': 'record another meter reading',
            'heading': 'Record Meter Reading for another Meter',
            'description': 'You are welcome to record meter readings for meters that were not requested in this reading list. These additional readings are forwarded directly to <1>{{applicationName}}</1> after they have been recorded. Please note that these additionally recorded readings will no longer be displayed here after they have been forwarded.',
            'loginDropdown': {
                'optionFallback': 'no identifier',
                'placeholder': 'Please select'
            },
            'notAllowedNotification': {
                'title': 'Not allowed',
                'message': 'You do not have the required rights for this function.'
            },
        },
    },
    'evaluation': {
        'hint': 'Select a meter in the menu on the left to show its evaluation.',
        'labels': {
            'readingsNotDone': 'Not done',
            'readingsWithoutEvaluation': 'Not evaluable',
        },
        'details': {
            'title': 'Meter Evaluation',
            'labels': {
                'consumptions': 'Consumptions',
                'history': 'Reading History',
                'meterAddress': 'Meter Address',
                'consumption': 'Consumption',
                'comparisonToPreviousConsumption': 'Comparison with last Consumption',
            },
        },
        'empty': {
            'noJobs': 'Insufficient data available. The evaluation is currently not possible.',
            'noReadingEventsForMeterRate': 'No meter reading results are available for the rate {{rateName}}.',
            'noReadingEventsForMeterRates': 'No meter reading results are available for the rates {{rateNames}}.',
            'noEvaluationPossibleWithRateName': 'No consumption evaluation can be performed for rate: {{rateName}}.',
            'noEvaluationPossibleNoRateName': 'No consumption evaluation can be performed for this rate.',
        },
    },
    'management': {
        'hint': 'Select an account in the menu on the left to share jobs.',
        'accounts': {
            'title': 'My Accounts',
            'reminder': {
                'subject': 'Meter Reading Reminder',
                'body': 'Dear {{name}},\n\n\nwe would like to remind you to enter your meter readings in the Meter Reading Portal.\n\nTo access your Meter Reading Portal, please click on the following link:\n\n{{login}}\n'
            },
            'labels': {
                'total': 'total',
            },
            'list': {
                'by': 'by',
            },
            'subaccounts': {
                'empty': 'You have not yet shared accounts.',
                'buttons': {
                    'share': 'Share Jobs',
                    'shareShort': 'Share'
                },
                'overview': {
                    'title': 'Created accounts',
                },
                'details': {
                    'openedByToken': 'This share was created by yourself. Here you can see the meters that are included in it.',
                    'description': 'This account contains {{count}} meter reading job',
                    'description_plural': 'This account contains {{count}}  meter reading jobs',
                    'delete': {
                        'confirm': {
                            'title': 'Delete account?',
                            'description': 'The account will be deleted, meter reading jobs and results will remain.',
                        },
                        'title': 'Remove meter reading from this account?',
                        'description': 'The meter reading job will be removed <1>from this Account</1>. It will remain in your meter reading jobs.',
                    },
                    'reshare': {
                        'title': 'Create new Access',
                        'description': 'Do you want to add a login? The current login will remain.',
                    },
                },
            },
            'share': {
                'title': 'Share Jobs',
                'task': 'job',
                'task_plural': 'jobs',
                'buttons': {
                    'share': 'Share {{count}} Job',
                    'share_plural': 'Share {{count}} Jobs',
                },
                'error': {
                    'maxjobs': 'A maximum of {{count}} jobs can be shared.'
                },
                'loading': 'loading jobs...',
                'labels': {
                    // 'status',
                },
                'empty': 'You don\'t have any jobs to share',
                'description': 'Please select the jobs you want to share.',
                'hint': '<0>Hint:</0> Click on the column names to sort the table. With a click on <3/> you can group by a column.',
                'tableColumns': {
                    'isShared': 'Shared',
                },
                'exportXlsx': {
                    'title': 'Download readings as an Excel file',
                    'description': 'If necessary, you can download the selected reading jobs as an Excel file. Please enter the readings here anyway, as a later Excel upload to take over the meter readings is not possible.',
                    'label': 'Download file',
                    'button': 'Excel file download',
                    'headers': {
                        'partnerIdentifier': '$t(meterReadingJob.partner.identifier)',
                        'partnerCompany': '$t(meterReadingJob.partner.company)',
                        'customerNumber': '$t(meterReadingJob.customer.customernumber)',
                        'customerCompany': '$t(meterReadingJob.customer.company) BP',
                        'customerMailingAddressStreet': '$t(meterReadingJob.address.street) BP',
                        'customerMailingAddressHouseNumber': '$t(meterReadingJob.address.houseNumber) BP',
                        'customerMailingAddressZip': '$t(meterReadingJob.address.zip) BP',
                        'customerMailingAddressCity': '$t(meterReadingJob.address.city) BP',
                        'maLoId': '$t(meterReadingJob.maLoId)',
                        'meterAddressStreet': 'Consumption Point $t(meterReadingJob.address.street)',
                        'meterAddressHouseNumber': 'Consumption Point $t(meterReadingJob.address.houseNumber)',
                        'meterAddressZip': 'Consumption Point $t(meterReadingJob.address.zip)',
                        'meterAddressCity': 'Consumption Point $t(meterReadingJob.address.city)',
                        'meterAddressObjectKey': 'Consumption Point $t(meterReadingJob.objectKey)',
                        'meterAddressLocationDescription': 'Consumption Point $t(meterReadingJob.address.locationDescription)',
                        'meterAddressHint': '$t(meterReadingJob.address.hint)',
                        'lastMeterReadingDate': 'last Reading Date',
                        'lastMeterReadingValue': 'last Reading Value',
                        'meterReadingDate': '$t(meterReadingJob.readingDate)',
                        'meterReadingValue': '$t(meterReadingJob.readingValue)',
                        'meterNumber': '$t(meterReadingJob.meterNumber)',
                        'meterType': '$t(meterReadingJob.meterType)',
                        'rateName': '$t(meterReadingJob.rates)',
                        'obis': '$t(meterReadingJob.obisCode)',
                        'targetReadingDate': '$t(common.targetReadingDate)',
                        'latestExecution': '$t(meterReadingJob.jobConstraints.latestExecution)',
                    },
                    'error' : {
                        'title': 'Excel-Export Error',
                        'message': 'An Error occurred while exporting data to Excel. Please try again later.'
                    }
                },
            },
        },
    },
    'help': {
        'global': {
            'contentTitle': 'Meter Reading Portal - Help',
            'tableOfContents': 'Table of Contents',
            'defaultText': 'Help is not available in the selected language.',
        },
        'sidePopOver': {
            'title': 'Reading help',
            'button': 'Help',
        },
    },
    'communication': {
        'triggerButton': {
            'registration': 'Sign Up Here',
            'communication': 'Send reading confirmation',
        },
        'home': {
            'title': 'Digital & Paperless Communication',
            'registration': {
                'description': 'By switching to digital communication, you can not only protect the environment and save time but also benefit from more efficient processes and better traceability.',
                'descriptionBulletPointsTitle': 'Your benefits:',
                'descriptionBulletPoints': [
                    'Confirmation of the successful reading by email',
                    'Compilation of all information about your reading',
                    'Fast and uncomplicated notifications via email in the future',
                ],
            },
            'communication': {
                'description': 'You are registered for digital communication and can request the reading confirmation via email.',
            }
        },
        'jobDetails': {
            'registration': {
                'description': 'By switching to digital communication, you can not only protect the environment and save time but also benefit from more efficient processes and better traceability.',
            },
            'communication': {
                'description': 'You are registered for digital communication and can request the reading confirmation via email.',
            }
        },
        'dialog': {
            'registration': {
                'title': 'Sign Up for Digital & Paperless Communication',
                'description': 'To receive the reading confirmation via email, please sign up for digital communication.',
                'content': 'Receive your reading confirmations conveniently via email. You can adjust your communication settings at any time if needed.',
                'missingURL': 'Signing up for digital communication is currently not possible. Please try again later.',
                'submitButtonText': 'Register',
                'cancelButtonText': 'Cancel',
            },
            'communication': {
                'title': 'Receive reading confirmation by email',
                'description': 'You are already signed up for digital communication. You can request the reading confirmations via email here.',
                'content': 'Sending the reading confirmation by email is free of charge for you. You have read one meter. Would you like to request a reading confirmation by email?',
                'content_plural': 'Sending the reading confirmation by email is free of charge for you. You have already read {{count}} meters. Would you like to request a reading confirmation by email?',
                'submitButtonText': 'Request reading confirmation',
                'cancelButtonText': 'Cancel',
            }
        },
        'notification': {
            'registration': {
                'done': {
                    'title': 'Successfully signed up for digital communication',
                    'description': 'From now on, you can receive a reading confirmation by email after you have completed a meter reading.'
                }
            },
            'communication': {
                'done': {
                    'title': 'Reading confirmation successfully requested',
                    'description': 'The reading confirmation for one meter has been sent to you by email.',
                    'description_plural': 'The reading confirmation for {{count}} meters has been sent to you by email.',
                },
                'error': {
                    'title': 'Request for reading confirmation failed',
                    'description': 'The reading confirmation by email for one meter could not be sent. Please try again later.',
                    'description_plural': 'The reading confirmation by email for {{count}} meters could not be sent. Please try again later.',
                }
            }
        }
    },
    'meterReadingJob': {
        'type': 'Type',
        'status': 'Status',
        'meter': 'Meter',
        'meLoId': 'Measurement Location',
        'maLoId': 'Market Location',
        'meterNumber': 'Meternumber',
        'meterType': 'Meter Type',
        'rates': 'Rate',
        'obisCode': 'OBIS',
        'objectKey': 'Object',
        'readingDate': 'Reading Date',
        'readingValue': 'Reading Value',
        'jobConstraints': {
            'earliestExecution': 'Earliest Execution',
            'latestExecution': 'Latest Execution',
        },
        'partner': {
            'title': 'partner',
            'identifier': 'Partner-No.',
            'company': 'Partner-Company',
            'name': 'Name',
        },
        'customer': {
            'title': 'Customer',
            'customernumber': 'Customernumber',
            'company': 'Company',
            'name': 'Name',
            'firstname': 'Firstname',
            'lastname': 'Lastname',
        },
        'address': {
            'title': 'Address',
            'fullAddress': 'Meter Address',
            'street': 'Street',
            'houseNumber': 'House Number',
            'zip': 'ZIP',
            'city': 'City',
            'cityDistrict': 'City-District',
            'hint': 'Reading Hint',
            'locationDescription': 'Location',
        },
        'aggregation': {
            'street': '{{count}} street',
            'street_plural': '{{count}} streets',
            'zip': '{{count}} zip',
            'zip_plural': '{{count}} zips',
            'city': '{{count}} city',
            'city_plural': '{{count}} cities',
            'cityDistrict': '{{count}} city-district',
            'cityDistrict_plural': '{{count}} city-districts',
            'customernumber': '{{count}} Cust.No.',
            'customernumber_plural': '{{count}} Cust.No.',
            'objectKey': '{{count}} object',
            'objectKey_plural': '{{count}} objects',
            'meter': '{{count}} meter',
            'meter_plural': '{{count}} meters',
            'latestExecution': '{{count}} expiration date',
            'latestExecution_plural': '{{count}} expiration dates',
        },
    },
    'offline': {
        'warning': 'Please don\'t close the browser!',
        'description' : 'We can\'t send your readings. They will be transmitted, as soon as the device has an internet connection.',
        'details': 'You can proceed with further meter readings. The readings will be queued and transmitted automatically, as soon as there is a stable internet connection.',
        'infopanel' : {
            'message' : '{{queueLength}} meter reading is queued',
            'message_plural' : '{{queueLength}} meter readings are queued',
            'details' : 'They will be sent, if you are online again.'
        },
        'buttons' : {
            'close': 'Hide hint',
            'retry': 'Retry'
        }
    },
    'menu': {
        'home': 'Home',
        'reading': 'My Meters',
        'evaluation': 'Evaluation',
        'management': 'Management',
        'help': 'Help',
        'accounts': 'My Accounts',
        'logout': 'Logout',
    },
    'common': {
        'metertypes': {
            'ELECTRIC_METER': 'Electric Meter',
            'HEAT_METER': 'Heat Meter',
            'GAS_METER': 'Gas Meter',
            'WATER_METER': 'Water Meter',
            'WATER_WARM_METER': 'Warm Water Meter',
            'GENERIC_METER': 'Meter',
        },
        'reasons': {
            'INITIAL_READING': 'Initial Reading',
            'PERIODIC_READING': 'Periodic Reading',
            'ESTIMATED_READING': 'Estimated Reading',
            'INTERMEDIATE_READING': 'Intermediate Reading',
            'METER_CHANGE': 'Meter Change',
            'OTHER': 'Other Reading',
            'OTHER_KSA': 'Customer Reading',
            'null': 'Unknown Reason'
        },
        'jobs': '{{count}} job',
        'jobs_plural': '{{count}} jobs',
        'reading': 'meter reading',
        'reading_plural': 'meter readings',
        'progress': 'progress',
        'targetReadingDate': 'target reading date',
        'loadingReadings': 'loading meter readings...',
        'loadingJobs': 'loading jobs...',
        'search': 'Search',
        'total': 'total',
        'until': 'until',
        'of': 'of',
        'loading': 'loading...',
        'imprint': 'Imprint',
        'dataPrivacy': 'Data Privacy Terms',
        'dialog': {
            'ok': 'OK',
            'abort': 'Abort',
        },
        'requiredField': 'Required Field',
        'fillRequiredFields': 'Please fill in required fields (*).',
        'account': {
            'noName': 'My Account',
        },
        'day': 'Day'
    },
};
