import { createSelector } from 'reselect';
import { ISearchState } from './search.types';

const getSubState = (state: any) => state.search as ISearchState;

const getSearchValue = createSelector(getSubState, (state) => state.search.value);
const getSearchArray = createSelector(getSearchValue, (state) => state?.split(' '));

const searchSelectors = { getSearchValue, getSearchArray };

export default searchSelectors;
