import React, { useMemo, useState } from 'react';
import ImageListItem from '../../../../../components/envago/ImageListItem/ImageListItem';
import Dialog from '../../../../../components/envago/Dialog/Dialog';
import { useTranslation } from 'react-i18next';
import Carousel, { PreviewImage } from '../../../../../components/envago/Carousel/Carousel';
import Button from '../../../../../components/envago/Button/Button';

interface JobReadingImagesProps {
    taskId: string;
    images: Array<MeterReadingPreviewImage>;
}

export interface MeterReadingPreviewImage extends PreviewImage {
    taskId: string;
    imageId: string;
}

const JobMeterRateTaskReadingImages = ({ taskId, images }: JobReadingImagesProps) => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [initialIndex, setInitialIndex] = useState<number | undefined>(undefined);

    const taskResultImages = useMemo(() => {
        return images.filter((i) => i.taskId === taskId);
    }, [images, taskId]);

    return (
        <>
            <div className={'flex flex-row gap-2'}>
                {taskResultImages.map((i) => {
                    return (
                        <ImageListItem
                            key={i.imageId}
                            showDetails={false}
                            image={i.base64}
                            imageSize={'large'}
                            onImageClick={() => {
                                setInitialIndex(i.index);
                                setOpen(true);
                            }}
                        />
                    );
                })}
            </div>
            <Dialog
                title={t('jobs.details.dialogs.meterRateTaskReadingImages.title')}
                classNameContent={'h-auto sm:h-full w-full sm:w-1/2'}
                visible={open}
                closable
                onClose={() => {
                    setOpen(false);
                    setTimeout(() => {
                        setInitialIndex(undefined);
                    }, 250);
                }}
                actions={[
                    <Button
                        primary
                        onClick={() => {
                            setOpen(false);
                            setTimeout(() => {
                                setInitialIndex(undefined);
                            }, 250);
                        }}
                    >
                        {t('jobs.details.dialogs.meterRateTaskReadingImages.closeButton')}
                    </Button>,
                ]}
            >
                <Carousel
                    images={images}
                    options={{
                        startIndex: initialIndex,
                        align: 'center',
                    }}
                />
            </Dialog>
        </>
    );
};

export default JobMeterRateTaskReadingImages;
