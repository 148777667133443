import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import React from 'react';

interface ImageListItemProps {
    image: string;
    imageSize?: 'small' | 'large';
    onImageClick?: () => void;
    showDetails?: boolean;
}

const ImageListItem = ({ image, imageSize = 'small', onImageClick, showDetails = true }: ImageListItemProps) => {
    const { t } = useTranslation();

    return (
        <div
            className={classNames('border rounded flex items-center px-2 py-1 mt-1 bg-gray-100 text-gray-500 text-sm', {
                'hover:opacity-60 cursor-pointer transition-opacity': !!onImageClick,
            })}
        >
            <div onClick={() => onImageClick && onImageClick()}>
                <img
                    src={image}
                    alt={''}
                    className={classNames('object-contain', {
                        'h-8 w-8': imageSize === 'small',
                        'h-24 w-24': imageSize === 'large',
                    })}
                />
            </div>
            {showDetails && (
                <>
                    <div className={'ml-2 flex-1 leading-none'}>
                        {t('jobs.details.imageUploaded')}
                        <br />
                        <small>{(image.length / 1024).toFixed(0)} kB</small>
                    </div>
                    <div className={'w-6  text-green-500'}>
                        <Icon path={Icon.Path.mdiCheck} />
                    </div>
                </>
            )}
        </div>
    );
};

export default ImageListItem;
