import React, { useCallback, useMemo, useState } from 'react';
import './AccountShareJobs.less';
import { jobsSelectors } from '../../../../redux/jobs';
import { useSelector } from 'react-redux';
import Loading from '../../../../components/common/Loading/Loading';
import Table, { AggregateValue } from '../../../../components/envago/Table/Table';
import { pluralHelper, splitJoin } from '../../../../helper/TextHelper';
import { resolveMeterType } from '../../../../helper/MeterHelper';
import EnergyIcon from '../../../../components/envago/EnergyIcon/EnergyIcon';
import { JobType } from '../../../../redux/jobs/jobs.types';
import Icon from '../../../../components/envago/Icon/Icon';
import Empty from '../../../../components/envago/Empty/Empty';
import { hasMultipleValues } from '../../../../helper/GroupHelper';
import { Trans, useTranslation } from 'react-i18next';
import { formatDate } from '../../../../helper/DateHelper';
import { sharesSelectors } from '../../../../redux/shares';
import { ColumnDef } from '@tanstack/react-table';
import { IndeterminateCheckbox } from '../../../../components/envago/IndeterminateCheckbox';

interface AccountShareJobPropTypes {
    accountId: string;
    onSelectionChanged?: (ids: string[], jobs: JobType[], groups: { name: string; data: JobType[] }[]) => void;
}

const AccountShareJobs = ({ accountId, onSelectionChanged }: AccountShareJobPropTypes) => {
    const { t } = useTranslation();
    const isLoading = useSelector(jobsSelectors.isLoading);

    const [jobs] = useState(useSelector(jobsSelectors.getShareableJobsByAccountId(accountId)));
    const [sharedJobs] = useState(useSelector(sharesSelectors.getSharedJobIdsForAccount(accountId)));

    const showColumns = useMemo(() => {
        return {
            'meter.meterAddress.objectKey': hasMultipleValues(jobs, 'meter.meterAddress.objectKey'),
            'customer.customerNumber': hasMultipleValues(jobs, 'customer.customerNumber'),
            'customer.name': hasMultipleValues(jobs, 'customer.firstname') || hasMultipleValues(jobs, 'customer.lastname'),
            'meter.meterAddress':
                hasMultipleValues(jobs, 'meter.meterAddress.street') ||
                hasMultipleValues(jobs, 'meter.meterAddress.houseNumber') ||
                hasMultipleValues(jobs, 'meter.meterAddress.zip') ||
                hasMultipleValues(jobs, 'meter.meterAddress.city'),
            'meter.meterAddress.street': hasMultipleValues(jobs, 'meter.meterAddress.street') || hasMultipleValues(jobs, 'meter.meterAddress.houseNumber'),
            'meter.meterAddress.zip': hasMultipleValues(jobs, 'meter.meterAddress.zip'),
            'meter.meterAddress.city': hasMultipleValues(jobs, 'meter.meterAddress.city'),
            'meter.meterAddress.cityDistrict': hasMultipleValues(jobs, 'meter.meterAddress.cityDistrict'),
            'customer.company': hasMultipleValues(jobs, 'customer.company'),
            partner: hasMultipleValues(jobs, 'partner.identifier') || hasMultipleValues(jobs, 'meterReadingJob.partner.name'),
            'jobConstraints.latestExecution': hasMultipleValues(jobs, 'jobConstraints.latestExecution'),
        };
    }, [jobs]);

    const isSharedSort = useCallback(
        (rowA: any, rowB: any) => {
            const idA = rowA.original.id;
            const idB = rowB.original.id;
            const a = sharedJobs.includes(idA);
            const b = sharedJobs.includes(idB);

            return a > b ? 1 : a < b ? -1 : 0;
        },
        [sharedJobs],
    );

    const meterTypeSort = useCallback((rowA: any, rowB: any) => {
        const a = resolveMeterType(rowA.original);
        const b = resolveMeterType(rowB.original);

        return a > b ? 1 : a < b ? -1 : 0;
    }, []);

    const statusSort = useCallback((rowA: any, rowB: any) => {
        const a = rowA.original.done;
        const b = rowB.original.done;

        return a > b ? 1 : a < b ? -1 : 0;
    }, []);

    const customerColumns = useMemo(() => {
        const columns = [
            showColumns['customer.customerNumber'] && {
                id: 'customer.customerNumber',
                header: t('meterReadingJob.customer.customernumber'),
                accessorFn: (row: JobType) => row.customer.customerNumber,
                aggregationFn: 'uniqueCount',
                aggregatedCell: ({ getValue }: any) => (
                    <AggregateValue>
                        {t('meterReadingJob.aggregation.customernumber', {
                            count: getValue(),
                        })}
                    </AggregateValue>
                ),
            },
            showColumns['customer.company'] && {
                id: 'customer.company',
                header: t('meterReadingJob.customer.company'),
                accessorFn: (row: JobType) => row.customer.company,
            },
            showColumns['customer.name'] && {
                id: 'meterReadingJob.customer.name',
                header: t('meterReadingJob.customer.name'),
                accessorFn: (row: JobType) => splitJoin(', ', row?.customer?.lastname, row?.customer?.firstname),
            },
        ].filter(Boolean);

        return columns.length > 0
            ? columns
            : [
                  {
                      id: 'meterReadingJob.customer.name',
                      header: t('meterReadingJob.customer.name'),
                      canSort: false,
                      accessorFn: (row: JobType) =>
                          row.customer.company ? row.customer.company : splitJoin(', ', row?.customer?.lastname, row?.customer?.firstname),
                  },
              ];
    }, [showColumns, t]);

    const addressColumns = useMemo(() => {
        const columns = [
            showColumns['meter.meterAddress'] && {
                id: 'meter.meterAddress',
                header: t('meterReadingJob.address.street'),
                canSort: true,
                aggregationFn: 'uniqueCount',
                accessorFn: (row: JobType) => splitJoin(' ', row?.meter.meterAddress?.street, row?.meter.meterAddress?.houseNumber),
                aggregatedCell: ({ getValue }: any) => <AggregateValue>{t('meterReadingJob.aggregation.street', { count: getValue() })}</AggregateValue>,
                cell: (props: any) => {
                    if (props.cell.isGrouped) {
                        return props.cell.row.groupByVal;
                    }

                    return splitJoin(' ', props.row.original?.meter.meterAddress?.street, props.row.original?.meter.meterAddress?.houseNumber);
                },
            },
            showColumns['meter.meterAddress'] && {
                id: 'meter.meterAddress.zip',
                header: t('meterReadingJob.address.zip'),
                size: 60,
                accessorFn: (row: JobType) => row.meter.meterAddress?.zip,
                aggregationFn: 'uniqueCount',
                aggregatedCell: ({ getValue }: any) => {
                    return <AggregateValue>{t('meterReadingJob.aggregation.zip', { count: getValue() })}</AggregateValue>;
                },
            },
            showColumns['meter.meterAddress'] && {
                id: 'meter.meterAddress.city',
                header: t('meterReadingJob.address.city'),
                accessorFn: (row: JobType) => row.meter.meterAddress?.city,
                aggregationFn: 'uniqueCount',
                aggregatedCell: ({ getValue }: any) => {
                    return <AggregateValue>{t('meterReadingJob.aggregation.city', { count: getValue() })}</AggregateValue>;
                },
            },
            showColumns['meter.meterAddress.cityDistrict'] && {
                id: 'meter.meterAddress.cityDistrict',
                header: t('meterReadingJob.address.cityDistrict'),
                canSort: false,
                accessorFn: (row: JobType) => row.meter.meterAddress?.cityDistrict,
                aggregationFn: 'uniqueCount',
                aggregatedCell: ({ getValue }: any) => {
                    return (
                        <AggregateValue>
                            {t('meterReadingJob.aggregation.cityDistrict', {
                                count: getValue(),
                            })}
                        </AggregateValue>
                    );
                },
            },
        ].filter(Boolean);

        return columns.length > 0
            ? columns
            : [
                  {
                      id: 'meter.meterAddress',
                      header: t('meterReadingJob.address.fullAddress'),
                      accessorFn: (row: JobType) => {
                          const street = splitJoin(' ', row?.meter.meterAddress?.street, row?.meter.meterAddress?.houseNumber);
                          const zip = row?.meter.meterAddress?.zip;
                          const city = row?.meter.meterAddress?.city;
                          const cityDistrict = row?.meter.meterAddress?.cityDistrict ? `(${row?.meter.meterAddress?.cityDistrict})` : undefined;

                          return splitJoin(' ', street, zip, city, cityDistrict);
                      },
                  },
              ];
    }, [showColumns, t]);

    const columns = useMemo<ColumnDef<JobType>[]>(
        () =>
            [
                {
                    id: 'select',
                    canSort: false,
                    enableGrouping: false,
                    size: 40,
                    header: ({ table }: any) => (
                        <div className="px-1">
                            <IndeterminateCheckbox
                                {...{
                                    checked: table.getIsAllRowsSelected(),
                                    indeterminate: table.getIsSomeRowsSelected(),
                                    onChange: table.getToggleAllRowsSelectedHandler(),
                                }}
                            />
                        </div>
                    ),
                    cell: ({ row }: any) => {
                        return (
                            <div className="px-1">
                                <IndeterminateCheckbox
                                    {...{
                                        checked: row.getIsSelected(),
                                        indeterminate: row.getIsSomeSelected(),
                                        onChange: row.getToggleSelectedHandler(),
                                    }}
                                />
                            </div>
                        );
                    },
                },
                {
                    header: t('management.accounts.share.tableColumns.isShared'),
                    id: 'management.accounts.share.tableColumns.isShared',
                    canSort: true,
                    sortType: isSharedSort,
                    sortInverted: true,
                    enableGrouping: false,
                    size: 60,
                    cell: SharedCell,
                },
                {
                    header: t('meterReadingJob.type'),
                    id: 'meterType',
                    canSort: true,
                    sortType: meterTypeSort,
                    enableGrouping: false,
                    size: 40,
                    cell: (props: any) => {
                        const meterType = resolveMeterType(props.row.original.meter);

                        return <EnergyIcon iconClassName={'h-5 w-5'} meterType={meterType} />;
                    },
                },
                {
                    header: t('meterReadingJob.status'),
                    id: 'status',
                    size: 50,
                    sortType: statusSort,
                    sortInverted: true,
                    enableGrouping: false,
                    accessor: 'done',
                    accessorFn: (row: JobType) => row.done,
                    aggregate: 'count',
                    cell: ({ getValue }: any) => {
                        return (
                            <div>
                                {getValue() ? (
                                    <Icon path={Icon.Path.mdiCheckCircleOutline} className={'h-5 text-green-500'} />
                                ) : (
                                    <Icon path={Icon.Path.mdiCheckboxBlankCircleOutline} className={'h-5 text-gray-300'} />
                                )}
                            </div>
                        );
                    },
                },
                {
                    header: t('meterReadingJob.meter'),
                    id: 'meter.meterNumber',
                    canSort: true,
                    enableGrouping: false,
                    accessorKey: 'meter.meterNumber',
                    accessorFn: (row: JobType) => row.meter.meterNumber,
                    aggregationFn: 'uniqueCount',
                    aggregatedCell: ({ getValue }: any) => <AggregateValue>{t('meterReadingJob.aggregation.meter', { count: getValue() })}</AggregateValue>,
                },
                showColumns['meter.meterAddress.objectKey'] && {
                    id: 'meter.meterAddress.objectKey',
                    header: t('meterReadingJob.objectKey'),
                    accessorFn: (row: JobType) => row.meter.meterAddress?.objectKey,
                    aggregatedCell: ({ value }: any) => <AggregateValue>{t('meterReadingJob.aggregation.objectKey', { count: value })}</AggregateValue>,
                },
                showColumns['partner'] && {
                    id: 'meterReadingJob.partner.title',
                    header: t('meterReadingJob.partner.title'),
                    enableGrouping: false,
                    columns: [
                        {
                            id: 'partner.identifier',
                            header: t('meterReadingJob.partner.identifier'),
                            accessorKey: 'partner.identifier',
                            accessorFn: (row: JobType) => row.partner?.identifier,
                            aggregationFn: 'uniqueCount',
                            aggregatedCell: ({ getValue }: any) => (
                                <AggregateValue>
                                    {getValue()} {pluralHelper(getValue(), 'Partner', 'Partner')}
                                </AggregateValue>
                            ),
                        },
                        {
                            id: 'partner.name',
                            header: t('meterReadingJob.partner.name'),
                            accessorFn: (row: any) => splitJoin(' ', row?.partner?.company, splitJoin(', ', row?.partner?.lastname, row?.partner?.firstname)),
                        },
                    ],
                },
                {
                    id: 'meterReadingJob.customer.title',
                    header: t('meterReadingJob.customer.title'),
                    enableGrouping: false,
                    columns: customerColumns,
                },
                {
                    id: 'meterReadingJob.address.title',
                    header: t('meterReadingJob.address.title'),
                    enableGrouping: false,
                    columns: addressColumns,
                },
                {
                    id: 'jobConstraints.latestExecution',
                    header: t('meterReadingJob.jobConstraints.latestExecution'),
                    accessor: 'jobConstraints.latestExecution',
                    accessorFn: (row: JobType) => {
                        if (!row.jobConstraints?.latestExecution) return null;
                        return formatDate(row.jobConstraints?.latestExecution);
                    },
                    aggregationFn: 'uniqueCount',
                    aggregatedCell: ({ getValue }: any) => {
                        return (
                            <AggregateValue>
                                {t('meterReadingJob.aggregation.latestExecution', {
                                    count: getValue(),
                                })}
                            </AggregateValue>
                        );
                    },
                },
            ]
                .filter((a) => a)
                .map((a: any) => a as ColumnDef<JobType>),

        [isSharedSort, meterTypeSort, showColumns, statusSort, t, customerColumns, addressColumns],
    );

    if (isLoading) return <Loading text={t('management.accounts.share.loading')} />;

    return (
        <>
            <div className="account-share-jobs text-sm flex flex-col max-h-full h-full">
                <div>
                    <p>{t('management.accounts.share.description')}</p>
                    <p>
                        <Trans i18nKey={'management.accounts.share.hint'}>
                            <i>Tipp:</i> Klicke auf die Spalten-Namen um die Tabelle zu sortieren. Mit einem Klick auf{' '}
                            <Icon className={'h-4 inline'} path={Icon.Path.mdiSelectGroup} />
                            {` `}
                            kannst du Aufträge gruppieren.
                        </Trans>
                    </p>
                </div>
                <div className={'flex-1 max-h-full max-w-full'}>
                    <Table
                        fullWidth={true}
                        groupable={true}
                        selectable={true}
                        className={''}
                        columns={columns}
                        onSelectionChange={(selection, rows, groups) => {
                            if (onSelectionChanged) {
                                onSelectionChanged(selection, rows, groups);
                            }
                        }}
                        empty={
                            <div>
                                <Empty>{t('management.accounts.share.empty')}</Empty>
                            </div>
                        }
                        data={jobs}
                    />
                </div>
            </div>
        </>
    );
};

const SharedCell = (props: any) => {
    const { id, login } = props.row.original as JobType;
    const isShared = useSelector(sharesSelectors.isJobInAccountShared(login, id));

    return isShared ? (
        <Icon path={Icon.Path.mdiCheckCircle} className={'h-5 text-primary'} />
    ) : (
        <Icon path={Icon.Path.mdiClose} className={'h-5 text-gray-300'} />
    );
};

export default AccountShareJobs;
