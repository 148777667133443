import { ReadingEventType, ReadingType } from '../redux/jobs/jobs.types';
import dayjs from 'dayjs';
import { formatDate } from './DateHelper';
import { IEvaluatedReadingType } from '../redux/evaluation/evaluation.types';

export const resolveLastReadingEventPerYear = (readingEvents: ReadingEventType[]) => {
    const lastReadingsEventPerYear = new Map<string, ReadingEventType>();

    readingEvents.forEach((reading) => {
        const currentYearReadingEvent = lastReadingsEventPerYear.get(
            formatDate(reading.readingDate, 'YYYY') as string,
        );

        if (currentYearReadingEvent && reading.readingDate) {
            if (dayjs(currentYearReadingEvent?.readingDate).isBefore(reading.readingDate)) {
                lastReadingsEventPerYear.set(
                    formatDate(reading.readingDate, 'YYYY') as string,
                    reading,
                );
            }
        } else if (reading.readingDate) {
            lastReadingsEventPerYear.set(
                formatDate(reading.readingDate, 'YYYY') as string,
                reading,
            );
        }
    });

    return Array.from(lastReadingsEventPerYear).map((reading) => {
        return reading[1];
    });
};

export const resolveReadingEventsConsumptions = (
    readings: ReadingEventType[],
    ignoreEstimatedReadingIfOtherReadingIsWithinDaysNum: number | null,
) => {
    const READINGS: IEvaluatedReadingType[] = [];

    const filteredReadings = readings
        .slice()
        .sort(
            (a, b) =>
                (a.readingDate && b.readingDate && b.readingDate.localeCompare(a.readingDate)) || 0,
        )
        .filter((r, index, arr) => {
            if (ignoreEstimatedReadingIfOtherReadingIsWithinDaysNum != null) {
                if (r.reason === 'ESTIMATED_READING') {
                    const readingBefore = arr[index + 1] !== undefined ? arr[index + 1] : undefined
                    const readingBeforeDiff = dayjs(r.readingDate).diff(
                        readingBefore?.readingDate,
                        'day',
                    );

                    const readingAfter = arr[index - 1] !== undefined ? arr[index - 1] : undefined
                    const readingAfterDiff = dayjs(readingAfter?.readingDate).diff(
                        r.readingDate,
                        'day',
                    );

                    if (readingBeforeDiff <= ignoreEstimatedReadingIfOtherReadingIsWithinDaysNum) {
                        return false;
                    } else if (
                        readingAfterDiff <= ignoreEstimatedReadingIfOtherReadingIsWithinDaysNum
                    ) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            } else {
                return true;
            }
        });

    for (let i = 0; i < filteredReadings.length; i) {
        let OFFSET = 1;

        if (filteredReadings[i].reason === ('ESTIMATED_READING' as ReadingType)) {
            READINGS.push({
                ...filteredReadings[i],
                consumption: undefined,
                consumptionPerDay: undefined,
                isGhost: true,
            } as IEvaluatedReadingType);

            i += OFFSET;
            continue;
        }

        if (filteredReadings[i + OFFSET]?.reason === ('ESTIMATED_READING' as ReadingType)) {
            for (let j = i + OFFSET; j < filteredReadings.length - 1; j++) {
                if (filteredReadings[j].reason === ('ESTIMATED_READING' as ReadingType)) {
                    READINGS.push({
                        ...filteredReadings[j],
                        consumption: undefined,
                        consumptionPerDay: undefined,
                        isGhost: true,
                    } as IEvaluatedReadingType);
                    OFFSET++;
                } else {
                    break;
                }
            }
        }

        const consumption =
            filteredReadings[i + OFFSET]?.readingValue != null
                ? filteredReadings[i].readingValue - filteredReadings[i + OFFSET].readingValue
                : undefined;

        const consumptionPerDay = resolveConsumptionPerDay(
            filteredReadings[i].readingDate,
            filteredReadings[i + OFFSET]?.readingDate,
            consumption,
        );

        READINGS.push({
            ...filteredReadings[i],
            consumption,
            consumptionPerDay,
            isGhost: filteredReadings[i].reason === ('ESTIMATED_READING' as ReadingType),
        } as IEvaluatedReadingType);

        i += OFFSET;
    }

    return READINGS.sort((a, b) => {
        if (!a.readingDate || !b.readingDate) return 0;

        if (dayjs(a.readingDate).isBefore(b.readingDate)) {
            return 1;
        } else {
            return -1;
        }
    });
};

export const resolveConsumptionPerDay = (from?: string, until?: string, consumption?: number) => {
    if (!from || !until || !consumption) return undefined;

    const diff = dayjs(from).diff(dayjs(until), 'day');

    return consumption / (diff === 0 ? 1 : diff);
};
